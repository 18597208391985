export const config = {
    app: {
        host: "https://bestel.mevlanakip.nl",
        port: 3000
    },
    api: {
        host: "https://bestel.mevlanakip.nl/api",
        port: 3000
    },
    kvk: {
        host: "https://bestel.mevlanakip.nl/kvk",
        port: 3000
    },
    payments: {
        host: "https://bestel.mevlanakip.nl/payments",
        port: 3000
    },
    ws: {
        host: "wss://bestel.mevlanakip.nl/ws",
        port: 3001
    },
    webpack: {
        host: "http://localhost:8080",
        port: 8080
    },
    snelstart: {
        host: "https://bestel.mevlanakip.nl/snelstart",
        port: 3001
    },
    token: "AIzaSyCdYmiOMYeEl-35AJeu5s12jEHbuVofhQU"
}
